import ApiService from '../apiservice'
import ErroValidacao from '../exception/ErroValidacao'

class UsuarioService extends ApiService {
  constructor() {
    super('/api/usuarios')
  }

  autenticar(credenciais) {
    return this.post('/autenticar', credenciais)
  }

  obterEquipesPorUsuario(id) {
    return this.get(`/${id}/equipe`)
  }

  obterLembreteSenha(email) {
    return this.post('/lembreteSenha', email, {
      headers: {
        'Content-Type': 'text/html; charset=utf-8'
      }
    })
  }

  salvar(usuario) {
    return this.post('', usuario)
  }

  validar(usuario) {
    const erros = []

    if (!usuario.nome) {
      erros.push('O campo Nome é obrigatório.')
      throw new ErroValidacao(erros)
    }

    if (!usuario.email) {
      erros.push('O campo Email é obrigatório.')
      throw new ErroValidacao(erros)
    } else if (
      !usuario.email.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9]+\.[a-z]/)
    ) {
      erros.push('Informe um Email válido.')
      throw new ErroValidacao(erros)
    }

    if (!usuario.genero) {
      erros.push('O campo Gênero é obrigatório')
      throw new ErroValidacao(erros)
    }

    if (!usuario.raca) {
      erros.push('O campo Raça é obrigatório')
      throw new ErroValidacao(erros)
    }

    if (!usuario.cpf) {
      erros.push('O campo CPF é obrigatório.')
      throw new ErroValidacao(erros)
    } else if (!usuario.cpf.match(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/)) {
      erros.push('Informe um CPF válido.')
      throw new ErroValidacao(erros)
    }

    if (!usuario.dataNascimento) {
      erros.push('O campo Data Nascimento é obrigatório.')
      throw new ErroValidacao(erros)
    }
    if (!usuario.observacao) {
      erros.push('O campo Como a equipe conheceu a ONDA é obrigatório.')
      throw new ErroValidacao(erros)
    }

    if (!usuario.senha || !usuario.senhaRepeticao) {
      erros.push('Digite a senha duas vezes.')
      throw new ErroValidacao(erros)
    } else if (usuario.senha !== usuario.senhaRepeticao) {
      erros.push('As senhas não podem ser diferentes.')
      throw new ErroValidacao(erros)
    }

    if (!usuario.fraseLembreteSenha) {
      erros.push('Frase de lembrete de senha:')
      throw new ErroValidacao(erros)
    }
    if (erros && erros.length > 0) {
      throw new ErroValidacao(erros)
    }
  }
}

export default UsuarioService
