import React from 'react'
import { withRouter } from 'react-router-dom'
import Card from '../components/card'
import FormGroup from '../components/form-group'
import { mensagemSucesso, mensagemErro } from '../components/toastr'
import EscolaService from '../app/service/escolaService'

class CadastroEscola extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nome: '',
      cep: '',
      cidade: '',
      rua: '',
      bairro: '',
      estado: '',
      numero: '',
      tipo: ''
    }
    this.service = new EscolaService()
  }

  cadastrar = () => {
    const { nome, cep, cidade, rua, bairro, estado, numero, tipo } = this.state
    const escola = { nome, cep, cidade, rua, bairro, estado, numero, tipo }

    try {
      this.service.validar(escola)
      this.service
        .salvar(escola)
        .then(() => {
          mensagemSucesso('Escola cadastrada com sucesso!')
          this.props.history.push('/cadastro-equipes')
        })
        .catch(error => {
          mensagemErro(error.response.data)
        })
    } catch (erro) {
      const msgs = erro.mensagens
      msgs.forEach(msg => mensagemErro(msg))
    }
  }

  handleChange = e => {
    const { name, value } = e.target
    this.setState({ [name]: value })
  }
  navigateTo = route => {
    window.location.href = route
  }

  render() {
    return (
      <Card title="Cadastro de Escola">
        <div className="row">
          <div className="col-lg-6">
            <FormGroup label="Nome da Escola: *" htmlFor="inputNomeEscola">
              <input
                type="text"
                id="inputNomeEscola"
                className="form-control"
                name="nome"
                placeholder="Digite o Nome da escola"
                value={this.state.nome}
                onChange={this.handleChange}
              />
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup
              label="Código de endereçamento postal (CEP): *"
              htmlFor="inputCep"
            >
              <input
                type="text"
                id="inputCep"
                className="form-control"
                name="cep"
                placeholder="Digite o CEP"
                value={this.state.cep}
                onChange={this.handleChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FormGroup label="Cidade: *" htmlFor="inputCidade">
              <input
                type="text"
                id="inputCidade"
                className="form-control"
                name="cidade"
                placeholder="Digite a Cidade"
                value={this.state.cidade}
                onChange={this.handleChange}
              />
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup label="Bairro: *" htmlFor="inputBairro">
              <input
                type="text"
                id="inputBairro"
                className="form-control"
                name="bairro"
                placeholder="Digite o Bairro"
                value={this.state.bairro}
                onChange={this.handleChange}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-2">
            <FormGroup label="Tipo da Escola: *" htmlFor="inputTipo">
              <select
                id="inputTipo"
                className="form-control"
                name="tipo"
                value={this.state.tipo}
                onChange={this.handleChange}
              >
                <option value="">Selecione...</option>
                <option value="Pública">Pública</option>
                <option value="Privada">Privada</option>
              </select>
            </FormGroup>
          </div>
          <div className="col-lg-4">
            <FormGroup label="Estado: *" htmlFor="inputEstado">
              <input
                type="text"
                id="inputEstado"
                className="form-control"
                name="estado"
                placeholder="Digite o Estado"
                value={this.state.estado}
                onChange={this.handleChange}
              />
            </FormGroup>
          </div>
          <div className="col-lg-4">
            <FormGroup label="Rua: *" htmlFor="inputRua">
              <input
                type="text"
                id="inputRua"
                className="form-control"
                name="rua"
                placeholder="Digite a Rua"
                value={this.state.rua}
                onChange={this.handleChange}
              />
            </FormGroup>
          </div>
          <div className="col-lg-2">
            <FormGroup label="Número: *" htmlFor="inputNumero">
              <input
                type="text"
                id="inputNumero"
                className="form-control"
                name="numero"
                placeholder="Digite o Número"
                value={this.state.numero}
                onChange={this.handleChange}
              />
            </FormGroup>
          </div>
        </div>
        <div>
          <button
            onClick={this.cadastrar}
            type="button"
            className="btn btn-success d-inline-flex align-items-center"
          >
            <i className="pi pi-save mr-2"></i> Salvar
          </button>

          <button
            onClick={() => this.navigateTo('/cadastro-equipes')}
            type="button"
            className="btn btn-danger ml-2 d-inline-flex align-items-center"
          >
            <i className="pi pi-times mr-2"></i> Cancelar
          </button>
        </div>
      </Card>
    )
  }
}

export default withRouter(CadastroEscola)
