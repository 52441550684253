import React from 'react'
import UsuarioService from '../app/service/usuarioService'
import { AuthContext } from '../main/provedorAutenticacao'
import * as messages from '../components/toastr'

class Home extends React.Component {
  state = {
    equipe: 0
  }

  constructor() {
    super()
    this.usuarioService = new UsuarioService()
  }

  componentDidMount() {
    const usuarioLogado = this.context.usuarioAutenticado

    this.usuarioService
      .obterEquipesPorUsuario(usuarioLogado.id)
      .then(response => {
        this.setState({ equipe: response.data })
      })
      .catch(error => {
        console.error(error.response)
      })
  }

  navigateTo = route => {
    window.location.href = route
  }

  render() {
    let mensagemEquipes = `Você possui um total de ${this.state.equipe} equipes cadastradas.`
    if (this.state.equipe === 1) {
      mensagemEquipes = 'Você possui um total de 1 equipe cadastrada.'
    }
    return (
      <div className="jumbotron">
        <h1 className="display-3">Bem vindo!</h1>
        <p className="lead">
          Esse é o sistema de cadastro de equipes da Olimpíada Nacional de
          Aplicativos - ONDA.
        </p>
        <p className="lead"> {mensagemEquipes}</p>
        <hr className="my-4" />
        <p>
          E essa é sua área administrativa, utilize um dos menus ou botões
          abaixo para navegar pelo sistema.
        </p>
        <div className="row justify-content-start">
          <div className="col-md-3 pl-3">
            <button
              className="btn btn-primary btn-lg btn-block d-flex align-items-center justify-content-center"
              onClick={() =>
                messages.mensagemAlert('Período de inscrição finalizado.')
              }
              style={{ marginBottom: '20px' }}
            >
              <i className="pi pi-user-plus mr-2"></i>
              Cadastrar Equipe
            </button>
          </div>
          <div className="col-md-3 pl-3">
            <button
              className="btn btn-danger btn-lg btn-block d-flex align-items-center justify-content-center"
              onClick={() => this.navigateTo('/consulta-equipes')}
              style={{ marginBottom: '20px' }}
            >
              <i className="pi pi-users mr-2"></i>
              Consultar Equipe
            </button>
          </div>
        </div>

        <div className="text-center">
          <a
            href="https://drive.google.com/file/d/1w5Agxy-KcNlrIfOu1dP18Ces9TGBFKKR/preview#view=FitH&zoom=100&page=1"
            className="btn btn-secondary btn-md"
            role="button"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginTop: '40px', marginBottom: '-20px' }}
          >
            Manual de Inscrição
          </a>
        </div>
      </div>
    )
  }
}

Home.contextType = AuthContext

export default Home
