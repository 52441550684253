import React from 'react'

import { withRouter } from 'react-router-dom'
import Card from '../components/card'
import FormGroup from '../components/form-group'

import Select from 'react-select'

import UsuarioService from '../app/service/usuarioService'
import { mensagemSucesso, mensagemErro } from '../components/toastr'

class CadastroUsuario extends React.Component {
  state = {
    nome: '',
    email: '',
    genero: '',
    raca: '',
    cpf: '',
    dataNascimento: null,
    observacao: '',
    senha: '',
    senhaRepeticao: '',
    fraseLembreteSenha: '',
    mostrarSenha: false
  }
  constructor() {
    super()
    this.service = new UsuarioService()
  }

  cadastrar = () => {
    const {
      nome,
      email,
      genero,
      raca,
      cpf,
      dataNascimento,
      observacao,
      senha,
      senhaRepeticao,
      fraseLembreteSenha
    } = this.state

    const dataNascimentoFormatada = dataNascimento
      ? dataNascimento.toISOString().split('T')[0]
      : null

    const usuario = {
      nome,
      email,
      raca,
      genero,
      cpf,
      dataNascimento: dataNascimentoFormatada,
      observacao,
      senha,
      senhaRepeticao,
      fraseLembreteSenha
    }

    try {
      this.service.validar(usuario)
    } catch (erro) {
      const msgs = erro.mensagens
      msgs.forEach(msg => mensagemErro(msg))
      return false
    }

    this.service
      .salvar(usuario)
      .then(response => {
        mensagemSucesso(
          'Usuário cadastrado com sucesso! Faça o login para acessar o sistema.'
        )
        this.props.history.push('/login')
      })
      .catch(error => {
        mensagemErro(error.response.data)
      })
  }

  cancelar = () => {
    this.props.history.push('/login')
  }

  toggleSenhaVisibility = () => {
    this.setState(prevState => ({
      mostrarSenha: !prevState.mostrarSenha
    }))
  }

  renderErros() {
    return this.state.erros.map((erro, index) => {
      return (
        <div key={index} className="alert alert-danger" role="alert">
          {erro}
        </div>
      )
    })
  }

  render() {
    return (
      <Card title="Cadastro de Usuário">
        <div className="row">
          <div className="col-lg-6">
            <FormGroup label="Nome Completo: *" htmlFor="inputNome">
              <input
                type="text"
                id="inputNome"
                className="form-control"
                name="nome"
                placeholder="Digite o Nome Completo"
                onChange={e => this.setState({ nome: e.target.value })}
              />
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup label="Email: *" htmlFor="inputEmail">
              <input
                type="email"
                id="inputEmail"
                className="form-control"
                name="email"
                placeholder="Digite o Email"
                onChange={e => this.setState({ email: e.target.value })}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form">
              <FormGroup htmlFor="inputGenero">
                <label htmlFor="inputGenero" className="label">
                  Gênero: *
                </label>
                <Select
                  name="genero"
                  options={[
                    { value: '1', label: 'Masculino' },
                    { value: '2', label: 'Feminino' },
                    { value: '3', label: 'Outro' }
                  ]}
                  placeholder="Selecione..."
                  onChange={selectedOption =>
                    this.setState({ genero: selectedOption.label })
                  }
                />
              </FormGroup>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form">
              <FormGroup name="raca">
                <label htmlFor="inputGenero" className="label">
                  Raça: *
                </label>
                <Select
                  name="raca"
                  options={[
                    { value: 'Preta', label: 'Preta' },
                    { value: 'Parda', label: 'Parda' },
                    { value: 'Indígena', label: 'Indígena' },
                    { value: 'Branca', label: 'Branca' },
                    { value: 'Amarela', label: 'Amarela' },
                    { value: 'Outro', label: 'Outro' }
                  ]}
                  placeholder="Selecione..."
                  onChange={selectedOption =>
                    this.setState({ raca: selectedOption.label })
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3">
            <FormGroup label="CPF: *" htmlFor="inputCPF">
              <input
                name="cpf"
                type="text"
                className="form-control"
                id="exampleInputCpf"
                described="cpfHelp"
                placeholder="Ex:000.000.000-00"
                onChange={e => this.setState({ cpf: e.target.value })}
              />
            </FormGroup>
          </div>
          <div className="col-md-3">
            <FormGroup
              label={`Data Nascimento : *`}
              htmlFor={`inputDataNascimento`}
            >
              <input
                type="date"
                id={`inputDataNascimento`}
                className="form-control"
                name={`dataNascimento`}
                placeholder="dd/mm/aaaa"
                onChange={e =>
                  this.setState({ dataNascimento: new Date(e.target.value) })
                }
              />
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <div className="form">
              <FormGroup name="raca">
                <label htmlFor="inputObservacao" className="label">
                  Como a equipe conheceu a ONDA: *
                </label>
                <Select
                  name="observacao"
                  options={[
                    { value: 'Redes sociais', label: 'Redes sociais' },
                    { value: 'YouTube', label: 'YouTube' },
                    { value: 'Jornal', label: 'Jornal' },
                    { value: 'Escola', label: 'Escola' },
                    { value: 'Amigo(a)', label: 'Amingo(a)' },
                    {
                      value: 'Evento,feira de ciência ou similar',
                      label: 'Evento,feira de ciência ou similar'
                    },
                    { value: 'TV', label: 'TV' },
                    {
                      value: 'Sites de divulgação de olimpíadas',
                      label: 'Sites de divulgação de olimpíadas'
                    },
                    { value: 'Outro', label: 'Outro' }
                  ]}
                  placeholder="Selecione..."
                  onChange={selectedOption =>
                    this.setState({ observacao: selectedOption.label })
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-lg-3">
            <FormGroup label="Senha: *" htmlFor="inputSenha">
              <div className="input-group">
                <input
                  type={this.state.mostrarSenha ? 'text' : 'password'}
                  id="inputSenha"
                  className="form-control"
                  name="senha"
                  placeholder="Digite sua Senha"
                  onChange={e => this.setState({ senha: e.target.value })}
                />
                <div className="input-group-append">
                  <span
                    className="input-group-text"
                    onClick={this.toggleSenhaVisibility}
                    style={{ cursor: 'pointer' }}
                  >
                    <i
                      className={`pi ${
                        this.state.mostrarSenha ? 'pi-eye-slash' : 'pi-eye'
                      }`}
                    ></i>
                  </span>
                </div>
              </div>
            </FormGroup>
          </div>
          <div className="col-lg-3">
            <FormGroup label="Repita a Senha: *" htmlFor="inputRepitaSenha">
              <input
                type="password"
                id="inputRepitaSenha"
                className="form-control"
                name="senha"
                placeholder="Repita a Senha"
                onChange={e =>
                  this.setState({ senhaRepeticao: e.target.value })
                }
              />
            </FormGroup>
          </div>

          <div className="col-lg-6">
            <FormGroup
              label="Frase de lembrete de senha: *"
              htmlFor="inputFraseLembreteSenha"
            >
              <input
                type="text"
                id="inputFraseLembreteSenha"
                className="form-control"
                name="fraseLembreteSenha"
                placeholder="Digite uma frase para lembrar sua senha"
                onChange={e =>
                  this.setState({ fraseLembreteSenha: e.target.value })
                }
              />
            </FormGroup>
          </div>
        </div>
        <div>
          <button
            onClick={this.cadastrar}
            type="button"
            className="btn btn-success d-inline-flex align-items-center"
          >
            <i className="pi pi-save mr-2"></i> Salvar
          </button>

          <button
            onClick={this.cancelar}
            type="button"
            className="btn btn-danger d-inline-flex align-items-center"
          >
            <i className="pi pi-times mr-2"></i> Cancelar
          </button>
        </div>
      </Card>
    )
  }
}

export default withRouter(CadastroUsuario)
