import ApiService from '../apiservice'
import ErroValidacao from '../exception/ErroValidacao'

class EquipeService extends ApiService {
  constructor() {
    super('/api')
  }

  salvar(equipe) {
    return this.post('/equipe', equipe)
  }

  async obterEquipePorId(id, usuarioId) {
    try {
      const response = await this.get(`/equipe/${id}?usuarioId=${usuarioId}`)

      return response.data
    } catch (error) {
      console.error(`Erro ao obter equipe por ID ${id}:`, error)
      throw error
    }
  }

  async atualizar(equipe, id, usuarioId) {
    try {
      if (!usuarioId) {
        throw new Error('ID do usuário não está definido.')
      }
      const response = await this.put(
        `/equipe/${id}?usuarioId=${usuarioId}`,
        equipe
      )
      return response.data
    } catch (error) {
      console.error(`Erro ao atualizar equipe:`, error)
      throw error
    }
  }
  async removerAluno(id) {
    try {
      await this.delete(`/aluno/${id}`)
    } catch (error) {
      console.error('Erro ao remover aluno:', error)
      throw error
    }
  }

  validar(equipe) {
    const erros = []

    if (!equipe.equipeNome.trim()) {
      erros.push('O campo Nome da equipe é obrigatório.')
    }

    equipe.alunos.forEach((aluno, index) => {
      if (!aluno.nome) {
        erros.push(`O campo Nome do ${index + 1}º aluno é obrigatório.`)
      }

      if (!aluno.email) {
        erros.push(`O campo Email do ${index + 1}º aluno é obrigatório.`)
      } else if (!aluno.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        erros.push(
          `O campo Email do ${index + 1}º aluno está em um formato inválido.`
        )
      }

      if (!aluno.genero) {
        erros.push(`O campo Gênero do ${index + 1}º aluno é obrigatório.`)
      }

      if (!aluno.raca) {
        erros.push(`O campo Raça do ${index + 1}º aluno é obrigatório.`)
      }

      if (!aluno.cpf) {
        erros.push(`O campo CPF do ${index + 1}º aluno é obrigatório.`)
      } else if (!aluno.cpf.match(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/)) {
        erros.push(
          `O campo CPF do ${index + 1}º aluno está em um formato inválido.`
        )
      }

      if (!aluno.dataNascimento) {
        erros.push(
          `O campo Data de Nascimento do ${index + 1}º aluno é obrigatório.`
        )
      }
    })

    if (erros.length > 0) {
      throw new ErroValidacao(erros)
    }
  }

  async obterOpcoesEscolas() {
    try {
      const response = await this.get('/escolas')
      return response.data
    } catch (error) {
      console.error('Erro ao obter opções de escolas:', error)
      return []
    }
  }

  async obterOpcoesCoorientadores() {
    try {
      const response = await this.get('/coorientadores')
      return response.data
    } catch (error) {
      console.error('Erro ao obter opções de coorientadores:', error)
      return []
    }
  }
}

export default EquipeService
