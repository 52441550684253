import React from 'react'

import Login from '../views/login'
import Home from '../views/home'
import CadastroUsuario from '../views/cadastroUsuario'
import CadastroCoorientador from '../views/cadastroCoorientador'
import ConsultaEquipes from '../views/consulta/consulta-equipes'
import CadastroEquipes from '../views/consulta/cadastro-equipes'
import LandingPage from '../views/landingPage'
import { AuthConsumer } from '../main/provedorAutenticacao'

import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom'
import cadastroEscola from '../views/cadastroEscola'

function RotaAutenticada({
  component: Component,
  isUsuarioAutenticado,
  ...props
}) {
  return (
    <Route
      exact
      {...props}
      render={componentProps => {
        if (isUsuarioAutenticado) {
          return <Component {...componentProps} />
        } else {
          return (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: componentProps.location }
              }}
            />
          )
        }
      }}
    />
  )
}

function Rotas(props) {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/cadastro-usuarios" component={CadastroUsuario} />

        <RotaAutenticada
          isUsuarioAutenticado={props.isUsuarioAutenticado}
          path="/home"
          component={Home}
        />
        <RotaAutenticada
          isUsuarioAutenticado={props.isUsuarioAutenticado}
          path="/consulta-equipes"
          component={ConsultaEquipes}
        />
        <RotaAutenticada
          isUsuarioAutenticado={props.isUsuarioAutenticado}
          path="/consulta-equipes/:id?"
          component={CadastroEquipes}
        />

        <RotaAutenticada
          isUsuarioAutenticado={props.isUsuarioAutenticado}
          path="/cadastro-escola"
          component={cadastroEscola}
        />

        <RotaAutenticada
          isUsuarioAutenticado={props.isUsuarioAutenticado}
          path="/cadastro-coorientador"
          component={CadastroCoorientador}
        />
      </Switch>
    </BrowserRouter>
  )
}

export default () => (
  <AuthConsumer>
    {context => <Rotas isUsuarioAutenticado={context.isAutenticado} />}
  </AuthConsumer>
)
