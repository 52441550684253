import React, { useState } from 'react'
import * as messages from '../../components/toastr'

export default function ConsultaTable(props) {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [projectLink, setProjectLink] = useState('')
  const [selectedEquipe, setSelectedEquipe] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const getStatusMessage = equipe => {
    if (equipe.status_id === 4) {
      if (equipe.segunda_etapa) {
        return (
          <div className="status-message">
            <span className="badge badge-success">Submetido</span>
          </div>
        )
      } else {
        return (
          <div className="status-message">
            <span className="badge badge-danger">Não Submetido</span>
          </div>
        )
      }
    }
    if (equipe.status_id === 5) {
      if (equipe.terceira_etapa) {
        return (
          <div className="status-message">
            <span className="badge badge-success">Submetido</span>
          </div>
        )
      } else {
        return (
          <div className="status-message">
            <span className="badge badge-danger">Não Submetido</span>
          </div>
        )
      }
    }

    return null
  }

  const handleOpenModal = equipe => {
    setSelectedEquipe(equipe)
    setProjectLink(equipe.link_projeto || '')
    setIsModalOpen(true)
    setErrorMessage('')
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
    setProjectLink('')
    setErrorMessage('')
  }

  const handleSaveLink = () => {
    if (!projectLink) {
      messages.mensagemAlert('Por favor, insira o link do projeto.')
      return
    }
    if (selectedEquipe && projectLink) {
      props.salvarLinkProjeto(selectedEquipe.id, projectLink)
      handleCloseModal()
    }
  }

  const rows = props.equipes.map(equipe => {
    const statusLabelStyle = equipe.status_color
      .split(',')
      .reduce((acc, cur) => {
        const [key, value] = cur.split(':')
        return {
          ...acc,
          [key.trim()]: value.trim()
        }
      }, {})

    let actions = null

    switch (equipe.status_id) {
      case 1:
        actions = (
          <div className="actions-container">
            <button
              type="button"
              title="Editar"
              className="btn btn-primary btn-sm d-inline-flex align-items-center"
              onClick={() => props.editAction(equipe.id)}
            >
              <i className="pi pi-pencil mr-1"></i> Editar
            </button>
            <button
              type="button"
              title="Excluir"
              className="btn btn-danger btn-sm d-inline-flex align-items-center"
              onClick={() => props.deleteAction(equipe)}
            >
              <i className="pi pi-trash mr-1"></i> Excluir
            </button>
          </div>
        )
        break
      case 2:
      case 6:
      case 3:
        actions = (
          <div className="actions-container">
            {equipe.status_id === 6 && (
              <button
                type="button"
                className="btn btn-info btn-sm d-inline-flex align-items-center"
                onClick={() => handleOpenModal(equipe)}
              >
                <i className="pi pi-link mr-1"></i> Adicionar Link
              </button>
            )}
          </div>
        )
        break
      case 4:
        actions = (
          <div className="actions-container">
            <label
              htmlFor={`file-pdf-${equipe.id}`}
              className="btn btn-secondary btn-sm d-inline-flex align-items-center"
              style={{
                padding: '0.2rem 0.2rem',
                fontSize: '0.85rem',
                lineHeight: '1.5',
                minWidth: '80px'
              }}
            >
              <i className="pi pi-upload mr-1"></i> 2ª Etapa
              <input
                id={`file-pdf-${equipe.id}`}
                type="file"
                style={{ display: 'none' }}
                accept=".pdf"
                onChange={e => props.enviarPdf(equipe.id, e.target.files[0])}
              />
            </label>
          </div>
        )
        break
      case 5:
        actions = (
          <div className="actions-container">
            <label
              htmlFor={`file-video-${equipe.id}`}
              className="btn btn-success btn-sm d-inline-flex align-items-center"
              style={{
                padding: '0.2rem 0.2rem',
                fontSize: '0.85rem',
                lineHeight: '1.5',
                minWidth: '80px'
              }}
            >
              <i className="pi pi-upload mr-1"></i> 3ª Etapa
              <input
                id={`file-video-${equipe.id}`}
                type="file"
                style={{ display: 'none' }}
                accept="video/*"
                onChange={e => props.enviarVideo(equipe.id, e.target.files[0])}
              />
            </label>
          </div>
        )
        break
      default:
        actions = null
        break
    }

    return (
      <tr key={equipe.id}>
        <td>{equipe.nome_escola}</td>
        <td>{equipe.nome}</td>
        <td style={statusLabelStyle}>{equipe.status_label}</td>
        <td>{getStatusMessage(equipe)}</td>
        <td>{actions}</td>
      </tr>
    )
  })

  return (
    <div>
      <div className="table-responsive">
        <table className="table table-hover">
          <thead>
            <tr>
              <th scope="col">Nome da Escola</th>
              <th scope="col">Equipe</th>
              <th scope="col">Situação</th>
              <th scope="col">Status</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
      </div>
      {isModalOpen && (
        <>
          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal">
            <div className="modal-dialog">
              <div className="modal-content">
                <div className="modal-header">
                  <h4 className="modal-title">🏆 Parabéns! 🏆</h4>
                  <button
                    type="button"
                    className="close"
                    onClick={handleCloseModal}
                  >
                    <span>&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Insira o link do projeto"
                    value={projectLink}
                    onChange={e => setProjectLink(e.target.value)}
                  />
                  {errorMessage && (
                    <div className="text-danger mt-2">{errorMessage}</div>
                  )}{' '}
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCloseModal}
                  >
                    Fechar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleSaveLink}
                  >
                    Salvar Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
