import ApiService from '../apiservice'
import ErroValidacao from '../exception/ErroValidacao'
import { mensagemSucesso, mensagemErro } from '../../components/toastr'

export default class ConsultaService extends ApiService {
  constructor() {
    super('/api')
  }

  obterPorId(id) {
    return this.get(`/equipe/${id}`)
  }

  alterarStatus(id, status) {
    return this.put(`/equipe/${id}/atualiza-status`, { status })
  }

  validar(consulta) {
    const erros = []

    if (!consulta.ano) {
      erros.push('Informe o Ano.')
    }

    if (!consulta.escola) {
      erros.push('Informe o Nome da Escola.')
    }

    if (!consulta.equipe) {
      erros.push('Informe o Nome da Equipe.')
    }

    if (erros.length > 0) {
      throw new ErroValidacao(erros)
    }
  }

  salvar(consulta) {
    return this.post('/equipe', consulta)
  }

  atualizar(consulta) {
    return this.put(`/equipe/${consulta.id}`, consulta)
  }

  consultar(consultaFiltro) {
    if (!consultaFiltro.ano) {
      throw new Error('Informe o ano para realizar a consulta.')
    }
    let params = `/equipe?usuarioId=${encodeURIComponent(
      consultaFiltro.usuarioId
    )}&ano=${encodeURIComponent(consultaFiltro.ano)}`

    if (consultaFiltro.escolaNome) {
      params += `&escolaNome=${encodeURIComponent(consultaFiltro.escolaNome)}`
    }

    if (consultaFiltro.equipeNome) {
      params += `&equipeNome=${encodeURIComponent(consultaFiltro.equipeNome)}`
    }

    return this.get(params)
  }

  deletar(id) {
    return this.delete(`/equipe/${id}`)
  }

  enviarPdf(idEquipe, arquivoPdf, history) {
    const formData = new FormData()
    formData.append('pdfFile', arquivoPdf)

    return super
      .post(`/equipe/${idEquipe}/enviar-pdf`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        mensagemSucesso('Seu projeto foi submetido com sucesso!')
        history.push('/home')
      })
      .catch(error => {
        mensagemErro('Erro ao enviar PDF: ' + error.message)
        throw error
      })
  }

  enviarVideo(idEquipe, arquivoVideo, history) {
    const formData = new FormData()
    formData.append('videoFile', arquivoVideo)

    return super
      .post(`/equipe/${idEquipe}/enviar-video`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(() => {
        mensagemSucesso('Seu vídeo pitch foi enviado com sucesso!')
        history.push('/home')
      })
      .catch(error => {
        mensagemErro('Erro ao enviar vídeo: ' + error.message)
        throw error
      })
  }

  salvarLinkProjeto(idEquipe, linkProjeto) {
    return this.post(`/equipe/${idEquipe}/link-projeto`, { linkProjeto })
      .then(() => {
        mensagemSucesso('Link do projeto salvo com sucesso!')
      })
      .catch(error => {
        mensagemErro('Erro ao salvar o link do projeto: ' + error.message)
        throw error
      })
  }
}
