import React from 'react'

function NavbarItem({ render, ...props }) {
  if (render) {
    const handleClick = e => {
      if (props.onClick) {
        e.preventDefault()
        props.onClick()
      }
    }

    return (
      <li className="nav-item">
        <a onClick={handleClick} className="nav-link" href={props.href}>
          {props.label}
        </a>
      </li>
    )
  } else {
    return null
  }
}

export default NavbarItem
