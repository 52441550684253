import ApiService from '../apiservice'
import ErroValidacao from '../exception/ErroValidacao'
import * as messages from '../../components/toastr'

class EscolaService extends ApiService {
  constructor() {
    super('/api/escola')
  }

  salvar(escola) {
    return this.post('', escola)
  }

  validar(escola) {
    if (!escola.nome) {
      throw new ErroValidacao(['O campo Nome da Escola é obrigatório.'])
    }

    if (!escola.cep) {
      throw new ErroValidacao(['O campo CEP é obrigatório.'])
    } else if (!escola.cep.match(/^\d{5}-\d{3}$/)) {
      throw new ErroValidacao(['Informe um CEP válido no formato XXXXX-XXX.'])
    }

    if (!escola.cidade) {
      throw new ErroValidacao(['O campo Cidade é obrigatório.'])
    }

    if (!escola.bairro) {
      throw new ErroValidacao(['O campo Bairro é obrigatório.'])
    }

    if (!escola.estado) {
      throw new ErroValidacao(['O campo Estado é obrigatório.'])
    }

    if (!escola.tipo) {
      throw new ErroValidacao(['O campo Tipo da Escola é obrigatório.'])
    }

    if (!escola.rua) {
      throw new ErroValidacao(['O campo Rua é obrigatório.'])
    }

    if (!escola.numero) {
      throw new ErroValidacao(['O campo Número é obrigatório.'])
    }
  }

  async obterOpcoesEscolas() {
    try {
      const response = await this.get('')
      return response.data
    } catch (error) {
      console.error('Erro ao obter escolas:', error)
      return []
    }
  }
}

export default EscolaService
