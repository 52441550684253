import React from 'react'
import Card from '../../components/card'
import FormGroup from '../../components/form-group'
import Select from 'react-select'
import { withRouter } from 'react-router-dom'
import * as messages from '../../components/toastr'
import ConsultaService from '../../app/service/consultaService'
import LocalStorageService from '../../app/service/localstorageService'
import EscolaService from '../../app/service/escolaService'
import CoorientadorService from '../../app/service/coorientadorService'
import EquipeService from '../../app/service/equipeService'
import { AuthContext } from '../../main/provedorAutenticacao'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'

const racaOptions = [
  { value: 'Preta', label: 'Preta' },
  { value: 'Parda', label: 'Parda' },
  { value: 'Indígena', label: 'Indígena' },
  { value: 'Branca', label: 'Branca' },
  { value: 'Amarela', label: 'Amarela' },
  { value: 'Outro', label: 'Outro' }
]

const generoOptions = [
  { value: 'Masculino', label: 'Masculino' },
  { value: 'Feminino', label: 'Feminino' },
  { value: 'Outro', label: 'Outro' }
]

class CadastroEquipes extends React.Component {
  state = {
    id: null,
    escolaId: '',
    coorientadorId: 0,
    equipeNome: '',
    ano: '',
    tipo: '',
    status: '',
    atualizando: false,
    genero: '',
    raca: '',
    dataNascimento: '',
    numeroParticipantes: {
      value: 2,
      label: ' '
    },
    alunos: new Array(4).fill(null),
    documentoDeficiencia: new Array(4).fill(null),
    opcoesEscolas: [],
    opcoesCoorientadores: [],
    showConfirmationDialog: false,
    adicionandoParticipante: false,
    showDeleteButton: false
  }

  constructor() {
    super()
    this.consultaService = new ConsultaService()
    this.escolaService = new EscolaService()
    this.coorientadorService = new CoorientadorService()
    this.equipeService = new EquipeService()
  }

  async componentDidMount() {
    const { id } = this.props.match.params

    await this.getOpcoesEscolas()
    this.getOpcoesCoorientadores()
    if (id) {
      this.setState({ atualizando: true })
      this.obterEquipePorId(id)
    }
  }

  obterEquipePorId = async id => {
    try {
      const equipe = await this.equipeService.obterEquipePorId(
        id,
        this.context.usuarioAutenticado.id
      )

      const escola = this.state.opcoesEscolas.find(
        c => c.value === equipe.escolaId
      )
      const coorientador = this.state.opcoesCoorientadores.find(
        a => a.value === equipe.coorientadorId
      )

      const alunos = equipe.alunos.map(aluno => ({
        id: aluno.id,
        ...aluno,
        genero: generoOptions.find(g => g.value === aluno.genero),
        raca: racaOptions.find(r => r.value === aluno.raca),
        dataNascimento: aluno.data_nascimento
      }))

      const documentoDeficienciaNome = alunos.map(
        aluno => aluno.documento_deficiencia
      )

      this.setState({
        id: equipe.id,
        escola,
        coorientador,
        escolaId: equipe.escolaId,
        coorientadorId: equipe.coorientadorId,
        equipeNome: equipe.nome,
        ano: equipe.ano,
        numeroParticipantes: {
          value: equipe.alunos.length,
          label: equipe.alunos.length
        },
        alunos,
        documentoDeficienciaNome
      })
    } catch (error) {
      console.log(error)
    }
  }

  async getOpcoesEscolas() {
    try {
      const opcoesEscolas = await this.escolaService.obterOpcoesEscolas()
      const opcoesFormatadas = opcoesEscolas.map(escola => ({
        value: escola.id,
        label: `${escola.nome} -${escola.estado}- ${escola.cidade} - ${escola.bairro} `
      }))
      this.setState({ opcoesEscolas: opcoesFormatadas })
    } catch (error) {
      console.error('Erro ao carregar opções de escolas:', error)
    }
  }

  async getOpcoesCoorientadores() {
    try {
      const opcoesCoorientadores =
        await this.coorientadorService.obterOpcoesCoorientadores()
      const opcoesFormatadas = opcoesCoorientadores.map(coorientador => ({
        value: coorientador.id,
        label: `${coorientador.nome} `
      }))
      this.setState({ opcoesCoorientadores: opcoesFormatadas })
    } catch (error) {
      console.error('Erro ao carregar opções de coorientadores:', error)
    }
  }

  navigateTo = route => {
    window.location.href = route
  }

  cadastrarEscola = () => {
    this.navigateTo('/cadastro-escola')
  }

  cadastrarCoorientador = () => {
    this.navigateTo('/cadastro-coorientador')
  }

  alterarNumeroParticipantes = value => {
    this.setState({ numeroParticipantes: value })
  }

  handleEscolaChange = escola => {
    if (escola) {
      this.setState({
        escolaId: escola.value,
        escola: { value: escola.value, label: escola.label }
      })
    } else {
      this.setState({
        escolaId: '',
        escola: null
      })
    }
  }

  handleCoorientadorChange = coorientador => {
    if (coorientador) {
      this.setState({
        coorientadorId: coorientador.value,
        coorientador: { value: coorientador.value, label: coorientador.label }
      })
    } else {
      this.setState({
        coorientadorId: '',
        coorientador: null
      })
    }
  }

  async submit(event) {
    event.preventDefault()

    const {
      id,
      escolaId,
      coorientadorId,
      alunos,
      equipeNome,
      numeroParticipantes
    } = this.state

    const erros = []
    const filteredAlunos = alunos.filter(Boolean)

    const formData = new FormData(event.target)

    try {
      if (!escolaId) {
        messages.mensagemErro('Por favor, escolha ou cadastre uma escola.')
        return
      }
      if (!equipeNome) {
        messages.mensagemErro('Por favor, preencha o nome da equipe.')
        return
      }
      if (
        isNaN(Number(numeroParticipantes.value)) ||
        +numeroParticipantes.value < 2
      ) {
        messages.mensagemErro('A equipe deve ter no mínimo 2 participantes.')
        return
      }

      const temFeminino = filteredAlunos.some(
        aluno => aluno.genero.value === 'Feminino'
      )

      if (!temFeminino) {
        messages.mensagemErro(
          'É necessário ter pelo menos uma pessoa do gênero feminino na equipe.'
        )
        return
      }

      if (erros.length > 0) {
        erros.forEach(erro => messages.mensagemErro(erro))
        throw new Error('Houve um problema de validação.')
      }

      let temErroAluno = false

      alunos.forEach((aluno, index) => {
        if (index < parseInt(numeroParticipantes.value)) {
          if (!aluno || !aluno.cpf) {
            temErroAluno = true
            messages.mensagemErro(
              `Por favor, preencha o CPF do ${index + 1}º participante.`
            )
            return
          } else if (!aluno.cpf.match(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/)) {
            temErroAluno = true
            messages.mensagemErro(
              `Informe um CPF válido para o ${index + 1}º participante.`
            )
            return
          }
          if (!aluno || !aluno.nome) {
            temErroAluno = true
            messages.mensagemErro(
              `Por favor, preencha o Nome do ${index + 1}º participante.`
            )
            return
          }
          if (!aluno || !aluno.email) {
            temErroAluno = true
            messages.mensagemErro(
              `Por favor, preencha o Email do ${index + 1}º participante.`
            )
            return
          } else if (
            !aluno.email.match(
              /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9]+\.[a-z]/
            )
          ) {
            temErroAluno = true
            messages.mensagemErro(
              `Informe um Email válido para o ${index + 1}º participante.`
            )
            return
          }

          if (!aluno || !aluno.dataNascimento) {
            temErroAluno = true
            messages.mensagemErro(
              `Por favor, preencha a Data de Nascimento do ${
                index + 1
              }º participante.`
            )
            return
          }
          if (!aluno || !aluno.genero) {
            temErroAluno = true
            messages.mensagemErro(
              `Por favor, preencha o Gênero do ${index + 1}º participante.`
            )
            return
          }
          if (!aluno || !aluno.raca) {
            temErroAluno = true
            messages.mensagemErro(
              `Por favor, preencha a Raça do ${index + 1}º participante.`
            )
            return
          }
        }
      })
      if (temErroAluno) {
        return
      }

      formData.set('usuarioId', this.context.usuarioAutenticado.id)
      formData.set('coorientadorId', coorientadorId || 0)
      formData.set('equipeNome', equipeNome)

      if (id) {
        formData.append('id', id)
        await this.equipeService.atualizar(
          formData,
          id,
          this.context.usuarioAutenticado.id
        )
        messages.mensagemSucesso('Equipe atualizada com sucesso!')
        this.props.history.push('/consulta-equipes')
      } else {
        await this.equipeService.salvar(formData)
        messages.mensagemSucesso('Equipe cadastrada com sucesso!')
        this.props.history.push('/consulta-equipes')
      }
    } catch (error) {
      if (error.response && error.response.data) {
        messages.mensagemErro(
          `Erro ao ${id ? 'atualizar' : 'cadastrar'} equipe: ${
            error.response.data
          }`
        )
      } else {
        console.error(
          `Erro ao ${id ? 'atualizar' : 'cadastrar'} equipe:`,
          error
        )
      }
    }
  }

  alterarCampoValorAluno(numeroParticipante, campo, valor) {
    const { alunos } = this.state

    this.setState({
      alunos: alunos.map((aluno, index) => {
        if (numeroParticipante === index) {
          const novoAluno = { ...(aluno || {}), [campo]: valor }

          if (campo === 'cpf' && this.verificarCPFRepetido(valor, index)) {
            messages.mensagemErro('CPF não pode ser duplicado.')
            return aluno
          }

          if (campo === 'email' && this.verificarEmailRepetido(valor, index)) {
            messages.mensagemErro('Email não pode ser duplicado.')
            return aluno
          }
          return novoAluno
        }
        return aluno
      })
    })
  }

  verificarCPFRepetido = (cpf, index) => {
    const { alunos } = this.state
    for (let i = 0; i < alunos.length; i++) {
      if (i !== index && alunos[i] && alunos[i].cpf === cpf) {
        return true
      }
    }
    return false
  }

  verificarEmailRepetido = (email, index) => {
    const { alunos } = this.state
    for (let i = 0; i < alunos.length; i++) {
      if (i !== index && alunos[i] && alunos[i].email === email) {
        return true
      }
    }
    return false
  }

  editar = id => {
    this.setState({ atualizando: true }, () => {
      this.navigateTo(`/cadastro-equipes/${id}`)
    })
  }

  showRemoverAlunoDialog = aluno => {
    this.setState({
      showConfirmationDialog: true,
      alunoParaRemover: aluno
    })
  }

  openConfirmationDialog = alunoId => {
    this.setState({
      showConfirmationDialog: true,
      alunoParaRemover: alunoId
    })
  }

  closeConfirmationDialog = () => {
    this.setState({
      showConfirmationDialog: false,
      alunoParaRemover: null
    })
  }

  adicionarAluno = () => {
    this.setState({
      showDeleteButton: false
    })
    const { alunos, numeroParticipantes } = this.state
    if (parseInt(numeroParticipantes.value) >= 4) {
      messages.mensagemErro('Você atingiu o limite máximo de 4 participantes.')
      return
    }
    const novoAluno = {
      id: null,
      cpf: '',
      nome: '',
      email: '',
      dataNascimento: '',
      genero: '',
      raca: '',
      documentoDeficiencia: ''
    }
    const novoNumeroParticipantes = {
      value: parseInt(numeroParticipantes.value) + 1,
      label: parseInt(numeroParticipantes.value) + 1
    }
    this.setState({
      alunos: [...alunos, novoAluno],
      numeroParticipantes: novoNumeroParticipantes,
      adicionandoParticipante: true
    })
  }
  removeAluno = index => {
    const { alunos, numeroParticipantes } = this.state
    const alunoToRemove = alunos[index]

    if (this.alunoTemCamposPreenchidos(alunoToRemove)) {
      messages.mensagemErro(
        'Aluno existente não podem ser removido, somente Deletado.'
      )
      this.setState({ showDeleteButton: true })
      return
    }

    if (numeroParticipantes.value > 2) {
      this.setState(prevState => ({
        alunos: prevState.alunos.filter((_, i) => i !== index),
        numeroParticipantes: {
          value: numeroParticipantes.value - 1,
          label: numeroParticipantes.value - 1
        }
      }))
    } else {
      messages.mensagemErro(
        'O número mínimo de participantes é 2. Não é possível remover mais alunos.'
      )
      return
    }
  }

  alunoTemCamposPreenchidos = aluno => {
    if (!aluno) return false
    return Object.values(aluno).some(value => !!value)
  }
  removerAluno = async alunoId => {
    try {
      if (this.state.alunos.length <= 2) {
        messages.mensagemErro(
          'Você não pode ter menos que 2 participantes na equipe.'
        )
        return
      }

      const updatedAlunos = this.state.alunos.filter(a => a.id !== alunoId)
      const temFeminino = updatedAlunos.some(
        aluno => aluno.genero.value === 'Feminino'
      )

      if (!temFeminino) {
        messages.mensagemErro(
          'É necessário ter pelo menos uma pessoa do gênero feminino na equipe.'
        )
        return
      }

      await this.equipeService.removerAluno(alunoId)

      this.setState({
        alunos: updatedAlunos
      })

      messages.mensagemSucesso('Aluno removido com sucesso!')
      this.closeConfirmationDialog()
      this.props.history.push('/consulta-equipes')
    } catch (error) {
      console.error('Erro ao remover aluno:', error)
      messages.mensagemErro(
        'Erro ao remover aluno. Por favor, tente novamente.'
      )
    }
  }

  handleDocumentoDeficienciaChange = (index, event) => {
    const { documentoDeficienciaNome } = this.state
    const modificadoDocumentoDeficienciaNome = { ...documentoDeficienciaNome }
    modificadoDocumentoDeficienciaNome[index] =
      (event.target.files[0] && event.target.files[0].name) || ''
    this.setState({
      documentoDeficienciaNome: modificadoDocumentoDeficienciaNome
    })
  }

  renderFields() {
    const {
      numeroParticipantes,
      alunos,
      atualizando,
      documentoDeficienciaNome
    } = this.state
    const fields = []

    for (let i = 0; i < parseInt(numeroParticipantes.value); i++) {
      fields.push(
        <div key={i} className="mb-3">
          <div className="row">
            <div className="col-sm-3">
              <FormGroup
                label={`CPF ${i + 1}º participante: *`}
                htmlFor={`inputCPF${i}`}
              >
                <input
                  name={`alunos[${i}][cpf]`}
                  type="text"
                  className="form-control"
                  id={`inputCPF${i}`}
                  placeholder="Ex: 000.000.000-00"
                  value={(alunos[i] && alunos[i].cpf) || ''}
                  onChange={e =>
                    this.alterarCampoValorAluno(i, 'cpf', e.target.value)
                  }
                  readOnly={atualizando && alunos[i] && alunos[i].id}
                />
              </FormGroup>
            </div>
            <div className="col-sm-4">
              <FormGroup
                label={`Nome ${i + 1}º participante: *`}
                htmlFor={`inputNome${i}`}
              >
                <input
                  type="text"
                  id={`inputNome${i}`}
                  className="form-control"
                  name={`alunos[${i}][nome]`}
                  placeholder="Digite o Nome"
                  value={(alunos[i] && alunos[i].nome) || ''}
                  onChange={e =>
                    this.alterarCampoValorAluno(i, 'nome', e.target.value)
                  }
                  readOnly={atualizando && alunos[i] && alunos[i].id}
                />
              </FormGroup>
            </div>
            <div className="col-sm-4">
              <FormGroup
                label={`Email ${i + 1}º participante: *`}
                htmlFor={`inputEmail${i}`}
              >
                <input
                  type="email"
                  id={`inputEmail${i}`}
                  className="form-control"
                  name={`alunos[${i}][email]`}
                  placeholder="Digite o Email"
                  value={(alunos[i] && alunos[i].email) || ''}
                  onChange={e =>
                    this.alterarCampoValorAluno(i, 'email', e.target.value)
                  }
                  readOnly={atualizando && alunos[i] && alunos[i].id}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup
                label={`Data Nascimento ${i + 1}º: *`}
                htmlFor={`inputDataNascimento${i}`}
              >
                <input
                  type="date"
                  id={`inputDataNascimento${i}`}
                  className="form-control"
                  name={`alunos[${i}][dataNascimento]`}
                  placeholder="dd/mm/aaaa"
                  value={(alunos[i] && alunos[i].dataNascimento) || ''}
                  onChange={e =>
                    this.alterarCampoValorAluno(
                      i,
                      'dataNascimento',
                      e.target.value
                    )
                  }
                  readOnly={atualizando && alunos[i] && alunos[i].id}
                />
              </FormGroup>
            </div>
            <div className="col-sm-3">
              <FormGroup
                label={`Gênero ${i + 1}º participa.: *`}
                htmlFor={`inputGenero${i}`}
              >
                <Select
                  name={`alunos[${i}][genero]`}
                  options={generoOptions}
                  placeholder="Selecione..."
                  value={(alunos[i] && alunos[i].genero) || ''}
                  onChange={selectedOption =>
                    this.alterarCampoValorAluno(i, 'genero', selectedOption)
                  }
                  isDisabled={atualizando && alunos[i] && alunos[i].id}
                />
              </FormGroup>
            </div>
            <div className="col-sm-5">
              <FormGroup
                label={`Raça ${i + 1}º participante: *`}
                htmlFor={`inputRaca${i}`}
              >
                <Select
                  name={`alunos[${i}][raca]`}
                  options={racaOptions}
                  placeholder="Selecione..."
                  value={(alunos[i] && alunos[i].raca) || ''}
                  onChange={selectedOption =>
                    this.alterarCampoValorAluno(i, 'raca', selectedOption)
                  }
                  isDisabled={atualizando && alunos[i] && alunos[i].id}
                />
              </FormGroup>
            </div>
            <div className="col-sm-6">
              <FormGroup
                label={`Documento de Deficiência do participante ${i + 1}:`}
                htmlFor={`inputDocumentoDeficiencia${i}`}
              >
                <div className="custom-file">
                  <input
                    disabled={atualizando && alunos[i] && alunos[i].id}
                    type="file"
                    id={`inputDocumentoDeficiencia${i}`}
                    className="custom-file-input"
                    name={`documentoDeficiencia[${i}]`}
                    accept="application/pdf"
                    onChange={e => this.handleDocumentoDeficienciaChange(i, e)}
                  />
                  <label
                    className="custom-file-label"
                    htmlFor={`inputDocumentoDeficiencia${i}`}
                  >
                    {documentoDeficienciaNome && documentoDeficienciaNome[i]
                      ? documentoDeficienciaNome[i]
                      : 'Escolha um arquivo PDF'}
                  </label>
                </div>
                <small className="form-text text-muted">
                  Realize o upload do documento PDF
                </small>
              </FormGroup>
            </div>
            {this.state.atualizando ? (
              <div
                style={{
                  marginTop: '20px',
                  marginLeft: '8px',
                  marginBottom: '20px'
                }}
              >
                <button
                  style={{ marginTop: '10px' }}
                  type="button"
                  className="btn btn-danger d-inline-flex align-items-center"
                  disabled={
                    this.state.adicionandoParticipante &&
                    !this.state.showDeleteButton
                  }
                  onClick={() => this.openConfirmationDialog(alunos[i].id)}
                >
                  <i className="pi pi-trash mr-2"></i> Deletar
                </button>

                {this.state.showConfirmationDialog && (
                  <Dialog
                    visible={this.state.showConfirmationDialog}
                    onHide={() =>
                      this.setState({
                        showConfirmationDialog: false,
                        alunoParaRemover: null
                      })
                    }
                    header="Confirmar Remoção de Aluno"
                    modal
                    footer={
                      <div>
                        <Button
                          label="Cancelar"
                          icon="pi pi-times"
                          className="p-button-text"
                          onClick={() =>
                            this.setState({
                              showConfirmationDialog: false,
                              alunoParaRemover: null
                            })
                          }
                        />
                        <Button
                          type="button"
                          label="Remover"
                          icon="pi pi-trash"
                          className="p-button-danger"
                          onClick={() =>
                            this.removerAluno(this.state.alunoParaRemover)
                          }
                        />
                      </div>
                    }
                  >
                    Tem certeza que deseja remover o aluno?
                  </Dialog>
                )}
              </div>
            ) : null}
          </div>
        </div>
      )
    }

    return (
      <div>
        {fields}
        <div
          className="row justify-content-center"
          style={{ marginBottom: '40px' }}
        >
          <div className="col-md-6 text-center">
            <button
              type="button"
              className="btn btn-secondary d-inline-flex align-items-center mb-2"
              onClick={this.adicionarAluno}
            >
              <i className="pi pi-user-plus mr-2"></i> Adicionar Aluno
            </button>
            <button
              type="button"
              className="btn btn-warning d-inline-flex align-items-center  mb-2"
              onClick={() => this.removeAluno(this.state.alunos.length - 1)}
              disabled={atualizando && alunos.length === 1 && alunos[0].id}
            >
              <i className="pi pi-user-minus mr-2"></i> Remover Aluno
            </button>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { atualizando, opcoesEscolas, opcoesCoorientadores, equipeNome } =
      this.state

    return (
      <Card
        title={
          this.state.atualizando
            ? 'Atualização de Equipe'
            : 'Cadastro de Equipes'
        }
      >
        <form method="POST" noValidate onSubmit={this.submit.bind(this)}>
          <div className="row">
            <div className="col-md-3 mb-3">
              <label htmlFor="inputEscola">Escola: *</label>
              <Select
                name="escolaId"
                options={opcoesEscolas}
                placeholder="Selecione / cadastre escola"
                onChange={this.handleEscolaChange}
                value={this.state.escola}
                isClearable
              />
            </div>
            <div className="col-md-2 mb-3">
              <button
                type="button"
                style={{ marginTop: '30px' }}
                onClick={this.cadastrarEscola}
                className="btn btn-primary btn-block"
              >
                Cadastrar Escola
              </button>
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="inputCoorientador">Coorientador: *</label>
              <Select
                name="coorientadorId"
                options={opcoesCoorientadores}
                placeholder="Selecione/cad coorientador"
                onChange={this.handleCoorientadorChange}
                value={this.state.coorientador}
                isClearable
              />
            </div>
            <div className="col-md-3 mb-3">
              <label htmlFor="inputCoorientador">
                Cadastro Coorientador(a):
              </label>
              <button
                type="button"
                onClick={this.cadastrarCoorientador}
                className="btn btn-primary btn-block"
              >
                Cadastrar Coorientador(a)
              </button>
            </div>
            <div className="col-md-6">
              <FormGroup label="Nome da equipe: *">
                <input
                  type="text"
                  className="form-control"
                  name="equipeNome"
                  placeholder="Digite o Nome da equipe"
                  value={equipeNome}
                  onChange={e => {
                    this.setState({ equipeNome: e.target.value })
                  }}
                />
              </FormGroup>
            </div>
          </div>

          {this.renderFields()}

          <div>
            {this.state.atualizando ? (
              <button
                type="submit"
                className="btn btn-success d-inline-flex align-items-center"
                style={{ width: '120px' }}
              >
                <i className="pi pi-refresh mr-2"></i> Atualizar
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-success d-inline-flex align-items-center"
                style={{ width: '120px' }}
              >
                <i className="pi pi-save mr-3"></i> Salvar
              </button>
            )}

            <span style={{ marginRight: '10px' }}></span>

            <button
              type="button"
              onClick={e => this.navigateTo('/home')}
              className="btn btn-danger d-inline-flex align-items-center"
              style={{ width: '120px' }}
            >
              <i className="pi pi-times mr-2"></i> Cancelar
            </button>
          </div>
        </form>
      </Card>
    )
  }
}
CadastroEquipes.contextType = AuthContext

export default withRouter(CadastroEquipes)
