import React from 'react'
import NavbarItem from './navbarItem'
import { AuthConsumer } from '../main/provedorAutenticacao'
import * as messages from '../components/toastr'

function Navbar(props) {
  const handleCadastroClick = () => {
    messages.mensagemAlert('Período de inscrição finalizado.')
  }

  return (
    <div className="navbar navbar-expand-lg fixed-top navbar-dark bg-primary">
      <div className="container">
        <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" />
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarResponsive"
          aria-controls="navbarResponsive"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end"
          id="navbarResponsive"
        >
          <ul className="navbar-nav">
            <NavbarItem
              render={props.isUsuarioAutenticado}
              href="/home"
              label="Home"
            />
            <NavbarItem
              render={props.isUsuarioAutenticado}
              href="/cadastro-usuarios"
            />
            <NavbarItem
              render={props.isUsuarioAutenticado}
              href="/cadastro-equipes"
              label="Cadastrar"
              onClick={handleCadastroClick}
            />
            <NavbarItem
              render={props.isUsuarioAutenticado}
              href="/consulta-equipes"
              label="Consultar"
            />
            <NavbarItem
              render={props.isUsuarioAutenticado}
              onClick={props.deslogar}
              href="/login"
              label="Sair"
            />
          </ul>
        </div>
      </div>
    </div>
  )
}

export default () => (
  <AuthConsumer>
    {context => (
      <Navbar
        isUsuarioAutenticado={context.isAutenticado}
        deslogar={context.encerrarSessao}
      />
    )}
  </AuthConsumer>
)
