import React from 'react'
import { withRouter } from 'react-router-dom'
import Card from '../../components/card'
import FormGroup from '../../components/form-group'
import ConsultaTable from './consultaTable'
import ConsultaService from '../../app/service/consultaService'
import * as messages from '../../components/toastr'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import { ProgressSpinner } from 'primereact/progressspinner'
import { AuthContext } from '../../main/provedorAutenticacao'
import './overlay.css'
import _ from 'lodash' // Import lodash for debounce

class ConsultaEquipes extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ano: '',
      equipeNome: '',
      escolaNome: '',
      showConfirmDialog: false,
      equipeDeletar: {},
      equipes: [],
      enviandoArquivo: false
    }
    this.service = new ConsultaService()
  }

  buscar = _.debounce(() => {
    if (!this.state.ano) {
      messages.mensagemAlert('O Ano deve ser informado<br/> para sua pesquisa.')
      return
    }

    const equipeFiltro = {
      ano: this.state.ano.trim(),
      equipeNome: this.state.equipeNome.trim().toLowerCase(),
      escolaNome: this.state.escolaNome.trim().toLowerCase(),
      usuarioId: this.context.usuarioAutenticado.id
    }

    this.service
      .consultar(equipeFiltro)
      .then(resposta => {
        const lista = resposta.data
        if (!Array.isArray(lista) || lista.length < 1) {
          messages.mensagemAlert('Nenhuma equipe encontrada.')
          this.setState({ equipes: [] })
        } else {
          this.setState({ equipes: lista })
        }
      })
      .catch(error => {
        console.log(error)
        messages.mensagemErro('Erro ao buscar equipes.')
      })
  }, 300)

  navigateTo = route => {
    window.location.href = route
  }

  editar = id => {
    this.navigateTo(`/cadastro-equipes/${id}`)
  }

  abrirConfirmacao = equipe => {
    this.setState({ showConfirmDialog: true, equipeDeletar: equipe })
  }

  cancelarDelecao = () => {
    this.setState({ showConfirmDialog: false, equipeDeletar: {} })
  }

  deletar = () => {
    this.service
      .deletar(this.state.equipeDeletar.id)
      .then(_response => {
        this.setState({
          equipes: this.state.equipes.filter(
            e => e.id !== this.state.equipeDeletar.id
          ),
          showConfirmDialog: false
        })
        messages.mensagemSucesso('Equipe deletada com sucesso!')
      })
      .catch(error => {
        messages.mensagemErro('Ocorreu um erro ao tentar deletar equipe.')
      })
  }

  preparaFormularioCadastro = () => {
    messages.mensagemAlert('Período de inscrição finalizado.')
  }

  alterarStatus = (equipe, status) => {
    this.service.alterarStatus(equipe.id, status).then(response => {
      const equipes = this.state.equipes
      const index = equipes.indexOf(equipe)
      if (index !== -1) {
        equipe['status'] = status
        equipes[index] = equipe
        this.setState({ equipes })
      }
      messages.mensagemSucesso('Status atualizado com sucesso!')
    })
  }

  enviarPdf = (id, arquivoPdf) => {
    this.setState({ enviandoArquivo: true })
    this.service
      .enviarPdf(id, arquivoPdf, this.props.history)
      .then(() => {
        this.setState({ enviandoArquivo: false })
      })
      .catch(error => {
        this.setState({ enviandoArquivo: false })
        messages.mensagemErro('Erro ao enviar PDF: ' + error.message)
      })
  }

  enviarVideo = (id, arquivoVideo) => {
    this.setState({ enviandoArquivo: true })
    this.service
      .enviarVideo(id, arquivoVideo, this.props.history)
      .then(() => {
        this.setState({ enviandoArquivo: false })
      })
      .catch(error => {
        this.setState({ enviandoArquivo: false })
        messages.mensagemErro('Erro ao enviar vídeo: ' + error.message)
      })
  }

  handleChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value }, () => {
      console.log(`${name}: ${value}`)
    })
  }

  render() {
    const { enviandoArquivo } = this.state

    const confirmDialogFooter = (
      <div>
        <Button label="Confirmar" icon="pi pi-check" onClick={this.deletar} />
        <Button
          label="Cancelar"
          icon="pi pi-times"
          onClick={this.cancelarDelecao}
          className="p-button-secondary"
        />
      </div>
    )

    return (
      <Card title="Consulta Equipes">
        <div className="row">
          <div className="col-md-6">
            <div className="bs-component">
              <FormGroup htmlFor="inputAno" label="Ano: *">
                <input
                  type="text"
                  className="form-control"
                  id="inputAno"
                  name="ano"
                  value={this.state.ano}
                  onChange={this.handleChange}
                  placeholder="Digite o Ano"
                />
              </FormGroup>

              <FormGroup htmlFor="inputEquipe" label="Nome da equipe: ">
                <input
                  type="text"
                  className="form-control"
                  id="inputEquipe"
                  name="equipeNome"
                  value={this.state.equipeNome}
                  onChange={this.handleChange}
                  placeholder="Digite o nome da equipe..."
                />
              </FormGroup>
              <FormGroup htmlFor="inputEscola" label="Nome da escola: ">
                <input
                  type="text"
                  className="form-control"
                  id="inputEscola"
                  name="escolaNome"
                  value={this.state.escolaNome}
                  onChange={this.handleChange}
                  placeholder="Digite o nome da escola..."
                />
              </FormGroup>

              <button
                onClick={this.buscar}
                type="button"
                className="btn btn-success d-inline-flex align-items-center"
              >
                <i className="pi pi-search mr-2"></i> Buscar
              </button>

              <button
                onClick={this.preparaFormularioCadastro}
                type="button"
                className="btn btn-danger d-inline-flex align-items-center"
              >
                <i className="pi pi-plus mr-2"></i> Cadastrar
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="row">
          <div className="col-md-12">
            <div className="bs-component">
              {enviandoArquivo && (
                <div className="overlay">
                  <div className="overlay-content">
                    <ProgressSpinner
                      style={{ width: '50px', height: '50px' }}
                      strokeWidth="4"
                    />
                    <p>Enviando arquivo, por favor aguarde...</p>
                  </div>
                </div>
              )}
              <ConsultaTable
                equipes={this.state.equipes}
                deleteAction={this.abrirConfirmacao}
                editAction={this.editar}
                alterarStatus={this.alterarStatus}
                enviarPdf={this.enviarPdf}
                enviarVideo={this.enviarVideo}
              />
            </div>
          </div>
        </div>
        <div>
          <Dialog
            header="Confirmação"
            visible={this.state.showConfirmDialog}
            style={{ width: '50vw' }}
            footer={confirmDialogFooter}
            modal={true}
            onHide={() => this.setState({ showConfirmDialog: false })}
          >
            Confirma a exclusão desta equipe?
          </Dialog>
        </div>
      </Card>
    )
  }
}

ConsultaEquipes.contextType = AuthContext

export default withRouter(ConsultaEquipes)
