import React from 'react'
import Card from '../components/card'
import FormGroup from '../components/form-group'
import { withRouter } from 'react-router-dom'
import UsuarioService from '../app/service/usuarioService'
import { mensagemAlert, mensagemErro } from '../components/toastr'
import { AuthContext } from '../main/provedorAutenticacao'
import LocalStorageService from '../app/service/localstorageService'

class Login extends React.Component {
  state = {
    email: '',
    senha: '',
    mostrarSenha: false,
    salvarSenha: false
  }

  constructor() {
    super()
    this.service = new UsuarioService()
  }

  componentDidMount() {
    const emailSalvo = LocalStorageService.getItem('email')
    const senhaSalva = LocalStorageService.getItem('senha')

    if (emailSalvo) {
      this.setState({ email: emailSalvo, salvarSenha: true })
    }
    if (senhaSalva) {
      this.setState({ senha: senhaSalva })
    }
  }

  entrar = () => {
    const { email, senha, salvarSenha } = this.state

    if (salvarSenha) {
      LocalStorageService.setItem('email', email)
      LocalStorageService.setItem('senha', senha)
    } else {
      LocalStorageService.removeItem('email')
      LocalStorageService.removeItem('senha')
    }

    this.service
      .autenticar({ email, senha })
      .then(response => {
        this.context.iniciarSessao(response.data)
        this.props.history.push('/home')
      })
      .catch(erro => {
        if (erro.response && erro.response.data) {
          mensagemErro(erro.response.data)
        } else {
          mensagemErro('Erro ao realizar login. Tente novamente mais tarde.')
        }
      })
  }

  prepareCadastrar = () => {
    this.props.history.push('/cadastro-usuarios')
  }

  obterLembreteSenha = () => {
    if (!this.state.email) {
      mensagemErro('Por favor, digite o email para obter o lembrete de senha.')
      return
    }

    this.service
      .obterLembreteSenha(this.state.email)
      .then(response => {
        if (response.data) {
          mensagemAlert(
            'Sua frase de lembrete de senha é:<br/> ' + response.data
          )
        } else {
          mensagemAlert(
            'Lembrete de senha não encontrado para o email fornecido'
          )
        }
      })
      .catch(error => {
        mensagemErro('Erro ao obter lembrete de senha.')
      })
  }

  toggleSenhaVisibility = () => {
    this.setState(prevState => ({
      mostrarSenha: !prevState.mostrarSenha
    }))
  }

  handleSalvarSenhaToggle = () => {
    this.setState(prevState => ({
      salvarSenha: !prevState.salvarSenha
    }))
  }

  render() {
    const { salvarSenha } = this.state
    const salvarSenhaClass = salvarSenha
      ? 'toggle-salvar-senha ativo'
      : 'toggle-salvar-senha'

    return (
      <div className="container">
        <div className="row justify-content-center mt-5">
          <div className="col-md-6">
            <Card title="Login">
              <div className="bs-component">
                <FormGroup label="Email: *" htmlFor="exampleInputEmail1">
                  <input
                    type="email"
                    value={this.state.email}
                    onChange={e => this.setState({ email: e.target.value })}
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="Digite o Email"
                  />
                </FormGroup>
                <FormGroup label="Senha: *" htmlFor="exampleInputPassword1">
                  <div className="input-group">
                    <input
                      type={this.state.mostrarSenha ? 'text' : 'password'}
                      value={this.state.senha}
                      onChange={e => this.setState({ senha: e.target.value })}
                      className="form-control"
                      id="exampleInputPassword1"
                      placeholder="Senha"
                    />
                    <div className="input-group-append">
                      <span
                        className="input-group-text"
                        id="togglePassword"
                        onClick={this.toggleSenhaVisibility}
                        style={{ cursor: 'pointer' }}
                      >
                        <i
                          className={`pi ${
                            this.state.mostrarSenha ? 'pi-eye-slash' : 'pi-eye'
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>
                </FormGroup>
                <div className={`form-check ${salvarSenhaClass}`}>
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="salvarSenhaCheck"
                    checked={salvarSenha}
                    onChange={this.handleSalvarSenhaToggle}
                  />
                  <label
                    className="form-check-label"
                    htmlFor="salvarSenhaCheck"
                  >
                    Salvar senha
                  </label>
                </div>
                <div
                  className="row"
                  style={{ marginBottom: '40px', marginTop: '20px' }}
                >
                  <div className="col-md-4" style={{ marginBottom: '10px' }}>
                    <button
                      onClick={this.entrar}
                      className="btn btn-success btn-block d-flex align-items-center justify-content-center"
                    >
                      <i className="pi pi-sign-in mr-2"></i> Entrar
                    </button>
                  </div>
                  <div className="col-md-4">
                    <button
                      onClick={this.prepareCadastrar}
                      className="btn btn-danger btn-block d-flex align-items-center justify-content-center"
                    >
                      <i className="pi pi-plus mr-2"></i> Cadastrar
                    </button>
                  </div>
                </div>

                <div className="row" style={{ marginTop: '-10px' }}>
                  <div className="mx-auto">
                    <button
                      onClick={this.obterLembreteSenha}
                      className="btn btn-secondary btn-block"
                    >
                      Obter lembrete de senha
                    </button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
        </div>
      </div>
    )
  }
}

Login.contextType = AuthContext

export default withRouter(Login)
