// authService.js

import LocalStorageService from './localstorageService'
import jwt from 'jsonwebtoken'
import ApiService from '../apiservice'

export const USUARIO_LOGADO = '_usuario_logado'
export const TOKEN = 'access_token'

export default class AuthService {
  static isUsuarioAutenticado() {
    const token = LocalStorageService.getItem(TOKEN)
    if (!token) {
      return false
    }
    const decodedToken = jwt.decode(token)
    const expiration = decodedToken.exp
    const isTokenInvalido = Date.now() >= expiration * 1000
    return !isTokenInvalido
  }

  static removerUsuarioAutenticado() {
    LocalStorageService.removeItem(USUARIO_LOGADO)
    LocalStorageService.removeItem(TOKEN)
  }

  static logar(usuario, token) {
    LocalStorageService.setItem(USUARIO_LOGADO, usuario)
    LocalStorageService.setItem(TOKEN, token)
    ApiService.registrarToken(token)
  }

  static obterUsuarioAutenticado() {
    return LocalStorageService.getItem(USUARIO_LOGADO)
  }

  static refreshSession() {
    const token = LocalStorageService.getItem(TOKEN)
    const usuario = AuthService.obterUsuarioAutenticado()
    AuthService.logar(usuario, token)
    return usuario
  }
}
