import ApiService from '../apiservice'
import * as messages from '../../components/toastr'
import ErroValidacao from '../exception/ErroValidacao'

class CoorientadorService extends ApiService {
  constructor() {
    super('/api')
  }

  salvar(coorientador) {
    try {
      this.validar(coorientador)
      return this.post('/coorientador', coorientador)
    } catch (erro) {
      messages.mensagemErro(erro.message)
      return Promise.reject(erro)
    }
  }

  validar(coorientador) {
    if (!coorientador.nome) {
      throw new ErroValidacao('O campo Nome é obrigatório.')
    }

    if (!coorientador.email) {
      throw new ErroValidacao('O campo Email é obrigatório.')
    } else if (
      !coorientador.email.match(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-z0-9]+\.[a-z]+$/
      )
    ) {
      throw new ErroValidacao('Informe um Email válido.')
    }

    if (!coorientador.genero) {
      throw new ErroValidacao('O campo Gênero é obrigatório.')
    }

    if (!coorientador.raca) {
      throw new ErroValidacao('O campo Raça é obrigatório.')
    }

    if (!coorientador.instituicao) {
      throw new ErroValidacao('O campo Instituição é obrigatório.')
    }

    if (!coorientador.curso) {
      throw new ErroValidacao('O campo Curso é obrigatório.')
    }

    if (!coorientador.cpf) {
      throw new ErroValidacao('O campo CPF é obrigatório.')
    } else if (!coorientador.cpf.match(/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/)) {
      throw new ErroValidacao('Informe um CPF válido.')
    }

    if (!coorientador.dataNascimento) {
      throw new ErroValidacao('O campo Data Nascimento é obrigatório.')
    }
  }

  async obterOpcoesCoorientadores() {
    try {
      const response = await this.get('/coorientador')
      return response.data
    } catch (error) {
      console.error('Erro ao obter coorientadores:', error)
      return []
    }
  }
}

export default CoorientadorService
