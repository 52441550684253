import React from 'react'
import { withRouter } from 'react-router-dom'

class LandingPage extends React.Component {
  goToHomePage = () => {
    this.props.history.push('/home')
  }

  render() {
    return (
      <div className="container text-center">
        <img src={process.env.PUBLIC_URL + '/logoPrincipal.png'} alt="Logo" />
        <h2 style={{ marginTop: '20px' }}>Bem vindo ao sistema</h2>
        <p>
          Este é o sistema de cadastro de equipes. Clique no botão abaixo para
          acessar o sistema:
        </p>

        <div className="row">
          <div className="col-md-4 offset-md-4">
            <button
              style={{ width: '100%', marginTop: '20px' }}
              onClick={this.goToHomePage}
              className="btn btn-success d-flex align-items-center justify-content-center"
            >
              <i className="pi pi-sign-in mr-2"></i> Acessar
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(LandingPage)
