import React from 'react'

import { withRouter } from 'react-router-dom'
import Card from '../components/card'
import FormGroup from '../components/form-group'

import Select from 'react-select'

import CoorietadorService from '../app/service/coorientadorService'
import { mensagemSucesso, mensagemErro } from '../components/toastr'

class CadastroCoorientador extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      nome: '',
      email: '',
      genero: '',
      raca: '',
      cpf: '',
      dataNascimento: null,
      instituicao: '',
      curso: ''
    }
    this.service = new CoorietadorService()
  }

  cadastrar = () => {
    const {
      nome,
      email,
      genero,
      raca,
      cpf,
      dataNascimento,
      observacao,
      instituicao,
      curso
    } = this.state
    const coorietador = {
      nome,
      email,
      genero,
      raca,
      cpf,
      dataNascimento,
      observacao,
      instituicao,
      curso
    }

    const dataNascimentoFormatada = dataNascimento
      ? dataNascimento.toISOString().split('T')[0]
      : null

    try {
      this.service.validar(coorietador)
      this.service
        .salvar(coorietador)
        .then(() => {
          mensagemSucesso('Coorietador cadastrado com sucesso!')
          this.props.history.push('/cadastro-equipes')
        })
        .catch(error => {
          mensagemErro(error.response.data)
        })
    } catch (erro) {
      const msgs = erro.mensagens
      if (Array.isArray(msgs)) {
        msgs.forEach(msg => mensagemErro(msg))
      } else {
        mensagemErro(msgs)
      }
    }
  }

  navigateTo = route => {
    window.location.href = route
  }

  cancelar = () => {
    this.navigateTo('/cadastro-equipes')
  }

  render() {
    return (
      <Card title="Cadastro de Coorientador(a)">
        <div className="row">
          <div className="col-lg-6">
            <FormGroup label="Nome Completo: *" htmlFor="inputNome">
              <input
                type="text"
                id="inputNome"
                className="form-control"
                name="nome"
                placeholder="Digite o Nome Completo"
                onChange={e => this.setState({ nome: e.target.value })}
              />
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup label="Email: *" htmlFor="inputEmail">
              <input
                type="email"
                id="inputEmail"
                className="form-control"
                name="email"
                placeholder="Digite o Email"
                onChange={e => this.setState({ email: e.target.value })}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <div className="form">
              <FormGroup htmlFor="inputGenero">
                <label htmlFor="inputGenero" className="label">
                  Gênero: *
                </label>
                <Select
                  name="genero"
                  options={[
                    { value: '1', label: 'Masculino' },
                    { value: '2', label: 'Feminino' },
                    { value: '3', label: 'Outro' }
                  ]}
                  placeholder="Selecione..."
                  onChange={selectedOption =>
                    this.setState({ genero: selectedOption.label })
                  }
                />
              </FormGroup>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="form">
              <FormGroup name="raca">
                <label htmlFor="inputGenero" className="label">
                  Raça: *
                </label>
                <Select
                  name="raca"
                  options={[
                    { value: 'Preta', label: 'Preta' },
                    { value: 'Parda', label: 'Parda' },
                    { value: 'Indígena', label: 'Indígena' },
                    { value: 'Branca', label: 'Branca' },
                    { value: 'Amarela', label: 'Amarela' },
                    { value: 'Outro', label: 'Outro' }
                  ]}
                  placeholder="Selecione..."
                  onChange={selectedOption =>
                    this.setState({ raca: selectedOption.label })
                  }
                />
              </FormGroup>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <FormGroup
              label="Instituicao de Ensino: *"
              htmlFor="inputInstituicao"
            >
              <input
                type="text"
                id="inpuInstituicao"
                className="form-control"
                name="instituicao"
                placeholder="Digite o nome da Instituicao de Ensino"
                onChange={e => this.setState({ instituicao: e.target.value })}
              />
            </FormGroup>
          </div>
          <div className="col-lg-6">
            <FormGroup label="Curso: *" htmlFor="inputCurso">
              <input
                type="text"
                id="inputCurso"
                className="form-control"
                name="curso"
                placeholder="Digite o nome do Curso"
                onChange={e => this.setState({ curso: e.target.value })}
              />
            </FormGroup>
          </div>
        </div>
        <div className="row" style={{ marginBottom: '20px' }}>
          <div className="col-lg-6">
            <FormGroup label="CPF: *" htmlFor="inputCPF">
              <input
                name="cpf"
                type="text"
                className="form-control"
                id="exampleInputCpf"
                described="cpfHelp"
                placeholder="Ex:000.000.000-00"
                onChange={e => this.setState({ cpf: e.target.value })}
              />
            </FormGroup>
          </div>
          <div className="col-md-4">
            <FormGroup
              label={`Data Nascimento : *`}
              htmlFor={`inputDataNascimento`}
            >
              <input
                type="date"
                id={`inputDataNascimento`}
                className="form-control"
                name={`dataNascimento`}
                placeholder="dd/mm/aaaa"
                onChange={e =>
                  this.setState({ dataNascimento: new Date(e.target.value) })
                }
              />
            </FormGroup>
          </div>
        </div>
        <div>
          <button
            onClick={this.cadastrar}
            type="button"
            className="btn btn-success d-inline-flex align-items-center"
            style={{ marginRight: '10px' }}
          >
            <i className="pi pi-save mr-2"></i> Salvar
          </button>

          <button
            onClick={this.cancelar}
            type="button"
            className="btn btn-danger d-inline-flex align-items-center"
          >
            <i className="pi pi-times mr-2"></i> Cancelar
          </button>
        </div>
      </Card>
    )
  }
}

export default withRouter(CadastroCoorientador)
